<template>
  <div>
    <h3 ref="intro">
      Exponent
    </h3>
    <br>
    <p>
      In mathematics, there are situations when you have to multiply a number by itself multiple times.<br><br>
      For example, the area of a square is equal to \(l \times l\) &mdash; where \(l\) is the length of its side. <br><br>
      In a similar fashion, the volume of a cube is equal to \(a \times a \times a\) &mdash; where \(a\) is the length of its side.
      <br><br>
      Exponents are a convenient way to express
      a number that is multipled with itself multiple times.
      <br><br>
      Exponent of a number tells you how many times that number is multiplied by itself.
      The number that is multipled with itself is called the <i>base</i>.
      <br><br>
      For example, when somebody says \(3^2\), which is read as <i>3 to the power 2</i>,  it basically means that 3 is multiplied by itself 2 times. So,
      $$3^2 = 3 \times 3$$
      Similarly,
      $$4^3 = 4 \times 4 \times 4$$
      <br><br>
      Exponents are also called powers.
    </p>
    <h3 ref="pg">
      MagicGraph | Exponent or Power of a Number
    </h3>
    <br>
    <h5>
      Getting Started
    </h5>
    <p>
      In this visual-interactive illustration, you will learn how to calculate the exponent (or power) of a number.
      The MagicGraph contains two blue input boxes. Use the blux input box at the bottom to enter the base number. Use the other input box to
      enter the exponent or the power. Once these input boxes are filled, the MagicGraph will automatically calculate the value of the exponent.
    </p>
    <br>
    <h5>
      Icons in the MagicGraph
    </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle and generate a new shape. You can tap on the
      <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Exponent',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Exponents & Powers';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is an Exponent?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Examples', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Exponents & Powers',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
